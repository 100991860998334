import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpService} from "../services/http/http.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private readonly httpService: HttpService,
                private readonly router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const token = this.httpService.getToken();
        this.httpService.getSystemUser();

        if (token) {
            return true;
        } else {
            this.router.navigate(['/error/401'], {state: {status: 401, title: 'Unauthorised'}});
            return false;
        }
    }

}
