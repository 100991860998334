import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import decode from 'jwt-decode';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
    enableProdMode();

    const token = sessionStorage.getItem('onboarding_token');
    if (token) {
        LogRocket.init(environment.logRocketAppID);
        const payload: any = decode(token);
        LogRocket.identify(payload.sub, {
            name: payload.entity_name,
        });
    }
}

Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environment,
    attachStacktrace: true,
    enabled: environment.sentryEnabled
})

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
