export const environment = {
    appVersion: '2.0.0',
    environment: 'production',
    production: true,
    apiUrl: 'https://services.bcb.group',
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    sentryDSN: 'https://aa9d5ddfabd84d9bb79861d582e03488@o266742.ingest.sentry.io/5238626',
    sentryEnabled: true,
    logRocketAppID: 'knbeyu/bcb-onboarding',
    maxFileUploadSizeBytes: 52428800
  };
  
