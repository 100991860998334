<div class='page'>
    <div class='content'>
        <div class='row'>
            <h1 class='col page__title'>Upload Tax Forms</h1>
        </div>
        <mat-divider class='mb-3'></mat-divider>
        <ng-container *ngIf='!showInfoPage; else infoPage'>
            <p>In order for our onboarding team to check that the forms have been completed correctly, please upload a scanned
                copy of the original <em>wet ink signed</em> documents to the onboarding portal.</p>
            <p>*Please note as the W8-BEN-E form is for the USA the date needs to be in US format MM-DD-YYYY</p>
            <p><i>*Please note that we will be unable to open any products or services until we have received the original wet
                ink signed copies of the forms*</i></p>
            <div class='link' (click)='showInfoPage = true'>More information</div>
            <div class="row" *ngIf="!formGroup; else form">
                <div class="d-flex flex-column align-items-center">
                    <mat-spinner>{{runInit() | async}}</mat-spinner>
                </div>
            </div>


        </ng-container>
        <div class='spacer'></div>
        <div class='btn-bar'>
            <button *ngIf="!systemUser$.value || showInfoPage" mat-flat-button class='bcb-btn primary' (click)='onNextClick()'
                    [disabled]='processing$.value'>{{processing$.value ? 'Processing...' : 'Next'}}</button>
            <button *ngIf="!systemUser$.value" mat-stroked-button class='bcb-btn primary' (click)='onPreviousClick()'
                    [disabled]='processing$.value'>{{processing$.value ? 'Processing...' : 'Previous'}}</button>
        </div>
    </div>
</div>

    <ng-template #form>
        <form [formGroup]='formGroup'>
            <div class="row">
                <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                       [currentStep]="currentStep.key"
                                       [entity]="entity"
                                       [formGroup]="formGroup"
                                       [questions]="questions"
                                       [questionsConfig]="questionsConfig"
                                       [visibleControls]="visibleControls"
                ></bcb-component-wrapper>

                <ng-container *ngIf='showJustification'>
                    <mat-divider style='margin: 25px 0'></mat-divider>
                    <div class='col-12 lbl-stacked'>
                        <mat-label>Justification</mat-label>
                        <mat-form-field appearance='outline'>
                            <textarea matInput [formControl]='justificationFormControl'
                                      (input)='formGroup.markAsDirty()' rows='4'></textarea>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </form>
    </ng-template>

    <ng-template #infoPage>
    <div class='mb-4 mt-4'>As part of your onboarding we also require you to complete and return the following forms:
        <ul>
            <li><a href='assets/pdf/AIA-Form.pdf' download='AIA'>AIA</a></li>
            <li><a href='assets/pdf/fw8bene.pdf' download='W8-BEN-E'>W8-BEN-E*</a></li>
            <li><a href='assets/pdf/Form_A_ENG_V202308.pdf' download='Form A'>Form A</a> - <i>for individuals and establishes
                whether the person to be identified is also the respective
                beneficial owner of the asset by means of a declaration.</i>
            </li>
            <li><a href='assets/pdf/Form_K_ENG_V202308.pdf' download='Form K'>Form K</a> - <i>If an operating (non-listed) legal
                entity (private company) is the beneficial owner of the
                assets,
                the person/persons controlling the legal entity (e.g. shareholders over 25%/managing
                directors/controllers)
                must be stated in the declaration. This is regardless of whether;</i>
                <ul>
                    <li>The person directly or indirectly owns the assets</li>
                    <li>They own it alone or together with third parties</li>
                </ul>
            </li>
            <li><a href='assets/pdf/Form_S_ENG_V1.0.pdf' download='Form S'>Form S</a> - To be completed if the onboarding entity (or ownership structure) includes a Foundation.
            </li>
            <li><a href='assets/pdf/Form_T_ENG_V1.0.pdf' download='Form T'>Form T</a> - To be completed if the onboarding entity (or ownership structure) includes a Trust.
            </li>
            <li><a href='assets/pdf/FATCA%20-%20BCB%20ADDITIONAL%20CERTIFICATION%20(FATCA%20CRS%20CERTIFICATION).pdf'
                   download='Fatca/CRS Declaration'>Fatca/CRS Declaration</a> - To be completed if classed as active
                NFFE
            </li>
        </ul>
    </div>
    <p>The forms need to be <em>wet ink signed</em> and sent to the following address:<br />
        Onboarding<br />
        BCB Group<br />
        5 Merchant Sq<br />
        London<br />
        W2 1AS</p>

    <p>In order for our onboarding team to check that the forms have been completed correctly, please upload a scanned
        copy of the original <em>wet ink signed</em> documents to the onboarding portal.</p>

    <div class='mb-4 mt-4'>The following guides are available to assist you in completing the above forms.
        <ul>
            <li><a href='assets/pdf/Instructions%20for%20Form%20W-8BEN-E.pdf' download='Instructions for Form W8-BEN-E'>Instructions
                for Form W8-BEN-E</a></li>
            <li><a href='assets/pdf/Form_K_ENG_V202308.pdf'
                   download='Form K Guide Template'>Form K Guide Template</a></li>
        </ul>
    </div>
    <p>*Please note as the W8-BEN-E form is for the USA the date needs to be in US format MM-DD-YYYY</p>
    <p><i>*Please note that we will be unable to open any products or services until we have received the original wet
        ink signed copies of the forms*</i></p>
</ng-template>

