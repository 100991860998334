import { Component } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-tax-forms',
	templateUrl: './tax-forms.component.html',
	styleUrls: ['./tax-forms.component.scss'],
})
export class TaxFormsComponent  extends BasePageComponent {
	showInfoPage: boolean = true;
	componentLayoutClassList: string[] = ['col-12'];
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.updateTaxForms(
				this.questionsConfig.getFormValuesMap(),
				this.entity.id
			)
	}

	onNextClick(): void {
		if(!this.showInfoPage) {
			super.onNextClick();
		} else {
			this.showInfoPage = false
		}
	}

	onPreviousClick(): void {
		if(this.showInfoPage) {
			super.onPreviousClick();
		} else {
			this.showInfoPage = true
		}
	}

}