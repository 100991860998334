import { Component } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	APP_ROUTES
} from '../../../../core/constants/enum.const';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { Observable, of } from "rxjs";
import { OnboardingStateModel } from "../../../../core/store/onboarding/onboarding.state";

@Component({
	selector: 'bcb-review-submit',
	templateUrl: './review-submit.component.html',
	styleUrls: ['./review-submit.component.scss'],
})
export class ReviewSubmitComponent extends BasePageComponent {

	private readonly optionalSteps = ['terms', 'tax_forms', 'welcome']
	private steps: Record<string, any> = {}
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onPreviousClick(): void {
		this.formGroup.markAsPristine()
		this.stepperQueries.navigateStep('prev');
	}

	handleOnboardingResponse(data: OnboardingStateModel) {
		super.handleOnboardingResponse(data);
		this.steps = this.entity?.profile?.onboarding?.steps ?? {}
		this.isDisabled = !this.canProceed();
	}

	private requiredStepsComplete(): boolean {
		return Object.keys(this.steps)
			.filter((step) => !this.optionalSteps.includes(step))
			.every((step) => this.steps[step]);
	}

	private canProceed(): boolean {
		return this.requiredStepsComplete() && this.formGroup.get('051573d4-2846-49fd-acba-4e8a3e8bf767')?.value === true;
	}

	onAnswerChange(): Observable<any> {
		this.isDisabled = !this.canProceed();
		return of(undefined);
	}

	onStepComplete(): Observable<any> {
		return this.http.completeOnboarding({
			entity_id: this.entity?.id
		})
	}

	afterStepSaveComplete() {
		this.http.logout().then(() => {
			window.location.href = `/${APP_ROUTES.completed}`;
		});
	}
}