import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../../core/services/http/http.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'bcb-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit, OnDestroy {
    verificationForm: FormGroup;

    entity_id: string = '';
    token: string = '';
    systemUser?: string;
    error?: string;
    loading: boolean = false;
    message?: string;

    unsubscribe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor(
        private readonly http: HttpService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly _snackBar: MatSnackBar
    ) {
        this.verificationForm = new FormGroup({
            otp: new FormControl(undefined, [
                Validators.minLength(5),
                Validators.maxLength(5),
                Validators.required,
                Validators.pattern('[0-9]*'),
            ]),
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            this.entity_id = params.entity_id;
            this.token = params.token;
            this.systemUser =
                this.route.snapshot.queryParamMap.get('systemUser') ?? undefined;

            if (this.systemUser) {
                this.http.setSystemUser(this.systemUser)
            }
            if (this.http.getToken()) {
                this.http.getTokenPayload();
                this.router.navigate(['/']);
            } else {
                this.authLogin();
            }
        });
    }

    authLogin(): void {
        this.error = undefined;
        this.loading = true;
        this.http
            .authLogin({
                entity_id: this.entity_id,
                token: this.token,
                systemUser: this.systemUser,
            })
            .pipe(first((data) => !!data))
            .subscribe(
                (data) => {
                    this.message = data.message;
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                    this.error = `Something went wrong. Please contact support for assistance`;
                    this._snackBar.open(this.error, undefined, {
                        panelClass: 'bg-danger',
                    });
                }
            );
    }

    onOtpChange(otp: string): void {
        this.verificationForm.get('otp')?.setValue(otp);
    }

    onSubmit() {
        if (this.verificationForm.valid) {
            const formData = {
                ...this.verificationForm.value,
                entity_id: this.entity_id,
                token: this.token,
            };
            this.loading = true;
            this.verificationForm.disable();
            this.http
                .authVerify({...formData, systemUserId: this.systemUser})
                .pipe(first((data) => !!data))
                .subscribe(
                    (data) => {
                        const token = data.access_token;
                        sessionStorage.setItem('onboarding_token', token);
                        this.loading = false;
                        this.router.navigate(['/']);
                    },
                    (error) => {
                        if (!error.status) {
                            this.error =
                                'Something went wrong. Please check your OTP and try again';
                            this._snackBar.open(`${this.error}`, undefined, {
                                duration: 5000,
                                verticalPosition: 'top',
                                horizontalPosition: 'right',
                                panelClass: 'bg-danger',
                            });
                        } else {
                            this._snackBar.open(
                                `${error.error.message}`,
                                undefined,
                                {
                                    duration: 5000,
                                    verticalPosition: 'top',
                                    horizontalPosition: 'right',
                                    panelClass: 'bg-danger',
                                }
                            );
                        }
                        this.loading = false;
                        this.verificationForm.enable();
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }
}
