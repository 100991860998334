<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Account Details</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
    </div>

    <p>Please fill out the required compliance information completely and as accurately as possible for a speedy risk
        assessment.<br/>Forecasts and projections do not need to be exact, only indicative of intended business
        activity.</p>

    <table mat-table [dataSource]="accounts">

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef> Currency</th>
            <td mat-cell *matCellDef="let element">
                <div class="shares">{{element.details?.currency}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="accountName">
            <th mat-header-cell *matHeaderCellDef> Account Name</th>
            <td mat-cell *matCellDef="let element"> {{element.details?.label || 'No label provided'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element; let index = index " class="justify-content-end">
                <div class="content">
                    <button *ngIf='!formGroup.disabled' mat-icon-button (click)="onEditAccount(element, index)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <ng-container
                            *ngIf="element.details?.status === 'complete' then successIcon; else errorIcon"></ng-container>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No accounts have been added</td>
        </tr>
    </table>

    <form *ngIf='showJustification'>
        <mat-divider style='margin: 25px 0'></mat-divider>
        <div class='col-12 lbl-stacked'>
            <mat-label>Justification</mat-label>
            <mat-form-field appearance='outline'>
                <textarea matInput [formControl]='justificationFormControl' (input)='isDirty = true' rows='4'></textarea>
            </mat-form-field>
        </div>
    </form>

    <div class="spacer"></div>

    <div class="btn-bar" *ngIf="!systemUser$.value">
        <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()">Next</button>
        <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()">Previous</button>
    </div>
</div>

<ng-template #successIcon>
    <img src="../../../../../assets/svg/success.svg" alt="Account complete">
</ng-template>
<ng-template #errorIcon>
    <img [matTooltip]="errorTooltip"
         matTooltipClass="tooltip-danger" matTooltipPosition="right"
         src="../../../../../assets/svg/error.svg" alt="Account error">
</ng-template>
