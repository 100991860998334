import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpService} from '../../../../core/services/http/http.service';
import {Subscription} from 'rxjs';
import {APP_ROUTES} from '../../../../core/constants/enum.const';
import {EntityQueries} from '../../../../core/store/entity/entity.queries';

@Component({
    selector: 'bcb-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
    entity_id: string = '';
    token: string = '';
    systemUser?: string;

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly http: HttpService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly entityQueries: EntityQueries
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.params.subscribe((params: Params) => {
                this.entity_id = params.entity_id;
                this.token = params.token;
                this.systemUser =
                    this.route.snapshot.queryParamMap.get('systemUser') ??
                    undefined;
                if (this.http.getToken()) {
                    this.http.getTokenPayload();
                    this.entityQueries.loadState();
                } else {
                    this.router.navigate(
                        [`/${APP_ROUTES.home}`],
                        {
                            skipLocationChange: true,
                        }
                    );
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
