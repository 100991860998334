import {
	AfterContentChecked,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { OrgChartNode } from '../../../org-chart/models/org-chart-node.interface';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { ShareholdersQueries } from '../../../../core/store/shareholders/shareholders.queries';
import { OrgChartUtil } from '../../../org-chart/utils/org-chart.util';
import { BehaviorSubject, Subscription } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { CorporateShareholderModalComponent } from './corporate-shareholder-modal/corporate-shareholder-modal.component';
import { Shareholder } from '../../../../core/models/shareholder.interface';
import { ShareholdersStateModel } from '../../../../core/store/shareholders/shareholders.state';
import { Entity } from '../../../../core/models/entity.model';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	ENTITY_TYPE,
	REVIEW_STATUS,
} from '../../../../core/constants/enum.const';
import { StepModel } from '../../../stepper/models/step.model';
import { first, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'bcb-shareholders',
	templateUrl: './shareholders.component.html',
	styleUrls: ['./shareholders.component.scss'],
})
export class ShareholdersComponent
	implements OnInit, AfterContentChecked, OnDestroy
{
	chartData?: OrgChartNode;
	entity?: Entity;
	modalActive: boolean = false;
	supportsZoom: boolean = false;
	showZoom: boolean = false;
	zoomLevel: number = 100;
	justificationFormControl: FormControl = new FormControl();
	showJustification: boolean = false;
	currentStep!: StepModel;
	systemUser$: BehaviorSubject<string | undefined>;

	subscriptions: Subscription = new Subscription();

	dialogConfig: MatDialogConfig = {
		width: '50%',
		disableClose: true,
		role: 'dialog',
	};

	processing: boolean = false;
	showLanding: boolean = true;
	isDisabled: boolean = false;

	constructor(
		private readonly stepperQueries: StepperQueries,
		private readonly entityQueries: EntityQueries,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly dialog: MatDialog,
		private readonly http: HttpService,
		private readonly _snackBar: MatSnackBar
	) {
		this.systemUser$ = http.systemUser
	}

	ngOnInit(): void {
		if (CSS.supports('(zoom: 100%)')) {
			this.supportsZoom = true;
		}

		this.entityQueries
			.getState()
			.pipe(first(data => !!data))
			.subscribe((data: Entity) => {
				this.entity = data;
			});

		this.stepperQueries
			.getStep()
			.pipe(first(data => !!data))
			.subscribe((currentStepData: StepModel) => {
				this.currentStep = currentStepData;
				if (currentStepData?.metadata?.disable ?? false) {
					this.isDisabled = true;
				}

				this.showJustification =
					this.entity?.profile?.onboarding?.latestReview?.review?.reviews?.find(
						(item) => item.section_id === currentStepData?.key
					)?.status === 'REJECTED';

				this.justificationFormControl.setValue(
					this.entity?.profile?.onboarding?.answers?.sections?.find(
						(item) => item.id === currentStepData?.key
					)?.answers?.justification
				);
			});

		this.subscriptions.add(
			this.shareholdersQueries
				.getState()
				.subscribe((data: ShareholdersStateModel) => {
					this.chartData = OrgChartUtil.shareholdersToOrgChartNode(
						data.shareholders,
						this.isDisabled
					);
					if (
						data.shareholders.some(
							(shareholder: Shareholder) =>
								shareholder.type === ENTITY_TYPE.sub_corporate
						)
					) {
						this.showLanding = false;
					}
					if (data.activeShareholder && !this.modalActive) {
						this.editShareholder(
							data.shareholders.find(
								(shareholder: Shareholder) =>
									shareholder.id === data.activeShareholder
							)
						);
					}

					if (this.isOverflowing()) {
						this.showZoom = true;
					} else {
						this.zoomLevel = 100;
						this.onZoomChange(100);
						this.showZoom = false;
					}
				})
		);
	}

	ngAfterContentChecked(): void {
		if (this.isOverflowing()) {
			this.showZoom = true;
		} else {
			this.zoomLevel = 100;
			this.onZoomChange(100);
			this.showZoom = false;
		}
	}

	onZoomChange(level: number | null): void {
		if (this.supportsZoom && this.showZoom) {
			const el = document.querySelector('bcb-org-chart>.tree.invert>ul') as HTMLElement
			el?.style.setProperty('zoom', `${Number(level)}%`)
		}
	}

	editShareholder(data?: Shareholder): void {
		const config: MatDialogConfig = {
			...this.dialogConfig,
			data: { ...data },
		};

		this.modalActive = true;
		const dialogRef = this.dialog.open(
			CorporateShareholderModalComponent,
			config
		);

		dialogRef.afterClosed().subscribe((data: Shareholder) => {
			this.shareholdersQueries.removeActiveShareholder();
			if (data) {
				this.shareholdersQueries.addUpdateShareholder(data);
				this.updateCorporateShareholder(data);
			}
			this.modalActive = false;
			this.entityQueries.loadSubEntities();
		});
	}

	addShareholder(): void {
		const dialogRef = this.dialog.open(CorporateShareholderModalComponent, {
			...this.dialogConfig,
			data: this.entity,
		});

		dialogRef.afterClosed().subscribe((data: Shareholder) => {
			this.shareholdersQueries.removeActiveShareholder();
			if (data) {
				this.updateCorporateShareholder(data);
			}

			this.modalActive = false;
		});
	}

	onNextClick(): void {
		if (this.showLanding) {
			this.showLanding = false;
		} else {
			this.processing = true;
			this.shareholdersQueries
				.getState()
				.pipe(
					first(),
					switchMap(() =>
						this.shareholdersQueries.getState().pipe(
							first(),
							switchMap((state) =>
								this.http.updateOnboardingAnswers(
									{
										id: this.currentStep.key,
										title: this.currentStep.title,
										status: REVIEW_STATUS.ENTITY_UPDATED,
										answers: {
											shareholders: [
												...state.shareholders,
											],
											justification:
												this.justificationFormControl
													.value,
										},
									},
									this.entity?.id ?? ''
								)
							)
						)
					),
					switchMap(() =>
						this.http.completeShareholder(this.entity?.id ?? '')
					)
				)
				.subscribe(
					() => {
						this.processing = false;
						this.entityQueries.loadState();
					},
					() => (this.processing = false)
				);
		}
	}

	onPreviousClick(): void {
		this.stepperQueries.navigateStep('prev');
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private mapFieldsCorporate(data: Entity): any {
		return {
			id: data.id.length > 0 ? data.id : undefined,
			parent_id: data.parent_id ?? this.entity?.id,
			first_name: data.first_name ?? undefined,
			last_name: data.last_name ?? undefined,
			entity_name: data.entity_name ?? undefined,
			email: data.email ?? undefined,
			shares: data.profile?.shares ?? undefined,
			shareholder: data.profile?.shareholder ?? undefined,
			mobile: data.mobile ?? undefined,
			complete: data.profile?.onboarding?.required_documents?.every(
				(item: any) => item.filename
			),
		};
	}

	private updateCorporateShareholder(data: Shareholder): void {
		this.http
			.updateCorporateSubEntity(this.mapFieldsCorporate(data))
			.subscribe(
				() => {
					this._snackBar.open(
						`Corporate shareholder ${data.entity_name} updated successfully`,
						undefined,
						{
							duration: 5000,
							verticalPosition: 'top',
							horizontalPosition: 'right',
						}
					);
					this.entityQueries.loadSubEntities();
				},
				(e) => {
					this._snackBar.open(
						`Error updating corporate shareholder ${data.entity_name}`,
						undefined,
						{
							duration: 5000,
							verticalPosition: 'top',
							horizontalPosition: 'right',
							panelClass: 'bg-danger',
						}
					);
					this.entityQueries.loadSubEntities();
				}
			);
	}

	private isOverflowing(): boolean {
		const el: HTMLElement | null = document.querySelector(
			'bcb-org-chart>.tree.invert>ul'
		);
		return Number(el?.clientWidth) > Number(el?.parentElement?.clientWidth);
	}
}
