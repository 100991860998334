import { v4 as uuid } from 'uuid';
import { Onboarding } from './onboarding.interface';

export class Entity {
	id: string = uuid();
	parent_id?: string | null;
	status?: string | null;
	type?: string | null;
	first_name?: string | null;
	middle_names?: string | null;
	last_name?: string | null;
	email?: string | null;
	mobile?: string | null;
	entity_name?: string | null;
	address?: Address = { entity_id: this.id };
	profile?: Profile = { entity_id: this.id };
	contactDetails?: Array<Contacts>;
	documents?: Array<Document> = [];
	sub_corporates?: Array<Entity> = [];
}

export interface Address {
	id?: string | null;
	entity_id?: string | null;
	type?: string | null;
	building_number?: string | null;
	building_name?: string | null;
	street?: string | null;
	sub_street?: string | null;
	state?: string | null;
	city?: string | null;
	postcode?: string | null;
	country?: string | null;
	created_at?: string | null;
	updated_at?: string | null;
	deleted_at?: string | null;
}

export interface Profile {
	id?: string | null;
	entity_id?: string | null;
	dob?: string | null;
	gender?: string | null;
	ccy?: string | null;
	shares?: string | number | null;
	title?: string | null;
	company_trading_name?: string | null;
	shareholder?: boolean;
	director?: boolean;
	tax_number?: string | null;
	company_registration_number?: string | null;
	company_business_nature?: string | null;
	company_website?: string | null;
	company_is_fund?: string | null;
	company_fund_type?: string | null;
	company_is_trust?: string | null;
	company_is_holding?: string | null;
	company_total_assets?: string | null;
	company_annual_income?: string | null;
	company_form?: string | null;
	onboarding?: Onboarding;
	onboarded_at?: string | null;
	onboarding_started_at?: string | null;
	created_at?: string | null;
	nationality?: string | null;
	job_title?: string | null;
	legal_person_type?: Array<string> | null;
}

export class Contacts {
	id?: string;
	entity_id?: string;
	type?: string;
	name?: string;
	position?: string;
	email?: string;
	mobile?: string;
}

export interface Document {
	meta_data?: Record<string, any>;
	document?: Record<string, any> | string;
	entity_id?: string;
	filename?: string;
	[key: string]: string | Record<string, any> | unknown;
}
