import { StepperConfigModel } from '../../../modules/stepper/models/stepper-config.model';
import { OnboardingStateModel } from '../onboarding/onboarding.state';
import { REVIEW_STATUS } from '../../constants/enum.const';
import { StepModelMetadata } from '../../../modules/stepper/models/step.model';
import { OnboardingReviewItem } from '../../models/onboarding-review-item.interface';

export class StepperConfig {
	onboarding: OnboardingStateModel = {};

	constructor(onboarding: OnboardingStateModel) {
		this.onboarding = onboarding;
	}

	getIndividualStepper(): StepperConfigModel {
		return {
			currentStep: 'welcome',
			steps: [
				{
					key: 'welcome',
					title: 'Welcome',
					complete: !!this.onboarding?.steps['welcome'],
					show: this.onboarding?.steps?.hasOwnProperty('welcome'),
					metadata: this.getStepMetadata('welcome'),
				},
				{
					key: 'profile',
					title: 'Profile Details',
					complete: !!this.onboarding?.steps['profile'],
					show: this.onboarding?.steps?.hasOwnProperty('profile'),
					metadata: this.getStepMetadata('profile'),
				},
				{
					key: 'address',
					title: 'Entity Registered Address',
					complete: !!this.onboarding?.steps['address'],
					show: this.onboarding?.steps?.hasOwnProperty('address'),
					metadata: this.getStepMetadata('address'),
				},
				{
					key: 'verification_documents',
					title: 'Verification Documents',
					complete:
						!!this.onboarding?.steps['verification_documents'],
					show: this.onboarding?.steps?.hasOwnProperty(
						'verification_documents'
					),
					metadata: this.getStepMetadata('verification_documents'),
				},
				{
					key: 'source_of_funds',
					title: 'Source of Funds',
					complete: !!this.onboarding?.steps['source_of_funds'],
					show: this.onboarding?.steps?.hasOwnProperty(
						'source_of_funds'
					),
					metadata: this.getStepMetadata('source_of_funds'),
				},
				{
					key: 'terms',
					title: 'Review and Legal',
					complete: !!this.onboarding?.steps['terms'],
					show: this.onboarding?.steps?.hasOwnProperty('terms'),
					metadata: this.getStepMetadata('terms'),
				},
			],
		};
	}

	getCorporateStepper(): StepperConfigModel {
		return {
			currentStep: 'welcome',
			steps: [
				{
					key: 'welcome',
					title: 'Welcome',
					complete: !!this.onboarding?.steps['welcome'],
					show: this.onboarding?.steps?.hasOwnProperty('welcome'),
					metadata: this.getStepMetadata('welcome'),
				},
				{
					key: 'entity',
					title: 'Contact Details',
					complete: !!this.onboarding?.steps['entity'],
					show: this.onboarding?.steps?.hasOwnProperty('entity'),
					metadata: this.getStepMetadata('entity'),
				},
				{
					key: 'profile',
					title: 'Legal Entity Details',
					complete: !!this.onboarding?.steps['profile'],
					show: this.onboarding?.steps?.hasOwnProperty('profile'),
					metadata: this.getStepMetadata('profile'),
				},
				{
					key: 'address',
					title: 'Entity Registered Address',
					complete: !!this.onboarding?.steps['address'],
					show: this.onboarding?.steps?.hasOwnProperty('address'),
					metadata: this.getStepMetadata('address'),
				},
				{
					key: 'contacts',
					title: 'Primary Contacts',
					complete: !!this.onboarding?.steps['contacts'],
					show: this.onboarding?.steps?.hasOwnProperty('contacts'),
					metadata: this.getStepMetadata('contacts'),
				},
				{
					key: 'directors',
					title: 'Legal Person Information',
					complete: !!this.onboarding?.steps['directors'],
					show: this.onboarding?.steps?.hasOwnProperty('directors'),
					metadata: this.getStepMetadata('directors'),
				},
				{
					key: 'shareholders',
					title: 'Shareholders',
					complete: !!this.onboarding?.steps['shareholders'],
					show: this.onboarding?.steps?.hasOwnProperty(
						'shareholders'
					),
					metadata: this.getStepMetadata('shareholders'),
				},
				{
					key: 'products',
					title: 'Account Details',
					complete: !!this.onboarding?.steps['products'],
					show:
						this.onboarding?.steps?.hasOwnProperty('products') &&
						this.onboarding?.questions?.find(
							(q) => q.step === 'products'
						)?.questions?.business_accounts?.length,
					metadata: this.getStepMetadata('products'),
				},
				{
					key: 'yield',
					title: 'Yield Product Due Diligence',
					complete: !!this.onboarding?.steps['yield'],
					show: this.onboarding?.steps?.hasOwnProperty('yield'),
					metadata: this.getStepMetadata('yield'),
				},
				{
					key: 'source_of_funds',
					title: 'Source of Funds / Wealth',
					complete: !!this.onboarding?.steps['source_of_funds'],
					show: true, // this.onboarding?.steps?.hasOwnProperty('source_of_funds'),
					metadata: this.getStepMetadata('source_of_funds'),
				},
				{
					key: 'due_diligence_form',
					title: 'Due Diligence',
					complete: !!this.onboarding?.steps['due_diligence_form'],
					show:
						this.onboarding?.steps?.hasOwnProperty(
							'due_diligence_form'
						) && this.onboarding?.due_diligence_form?.steps,
					metadata: this.getStepMetadata('due_diligence_form'),
					subSteps: [
						{
							key: 'regulatoryInformation',
							title: 'Regulatory Information',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'regulatoryInformation'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'regulatoryInformation'
							),
							metadata: this.getStepMetadata(
								'regulatoryInformation'
							),
						},
						{
							key: 'antiMoneyLaunderingPoliciesAndProcedures',
							title: 'AML Policies and Procedures',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'antiMoneyLaunderingPoliciesAndProcedures'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'antiMoneyLaunderingPoliciesAndProcedures'
							),
							metadata: this.getStepMetadata(
								'antiMoneyLaunderingPoliciesAndProcedures'
							),
						},
						{
							key: 'antiMoneyLaunderingPoliciesAndProceduresLite',
							title: 'AML Policies and Procedures',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'antiMoneyLaunderingPoliciesAndProceduresLite'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'antiMoneyLaunderingPoliciesAndProceduresLite'
							),
							metadata: this.getStepMetadata(
								'antiMoneyLaunderingPoliciesAndProceduresLite'
							),
						},
						{
							key: 'boardAndSeniorManagement',
							title: 'Legal Persons and Senior Management',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'boardAndSeniorManagement'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'boardAndSeniorManagement'
							),
							metadata: this.getStepMetadata(
								'boardAndSeniorManagement'
							),
						},
						{
							key: 'antiMoneyLaunderingOfficer',
							title: 'AML Officer',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'antiMoneyLaunderingOfficer'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'antiMoneyLaunderingOfficer'
							),
							metadata: this.getStepMetadata(
								'antiMoneyLaunderingOfficer'
							),
						},
						{
							key: 'ComplianceSystemsAndProcedures',
							title: 'Compliance procedures',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'ComplianceSystemsAndProcedures'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'ComplianceSystemsAndProcedures'
							),
							metadata: this.getStepMetadata(
								'ComplianceSystemsAndProcedures'
							),
						},
						{
							key: 'businessModelAndCustomerBase',
							title: 'Business Model and Customer Base',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'businessModelAndCustomerBase'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'businessModelAndCustomerBase'
							),
							metadata: this.getStepMetadata(
								'businessModelAndCustomerBase'
							),
						},
						{
							key: 'kycProcedures',
							title: 'Know Your Customer Procedures',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'kycProcedures'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'kycProcedures'
							),
							metadata: this.getStepMetadata('kycProcedures'),
						},
						{
							key: 'pepSanctionsChecks',
							title: 'Screening Controls',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'pepSanctionsChecks'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'pepSanctionsChecks'
							),
							metadata:
								this.getStepMetadata('pepSanctionsChecks'),
						},
						{
							key: 'transactionMonitoring',
							title: 'Transaction Monitoring',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'transactionMonitoring'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'transactionMonitoring'
							),
							metadata: this.getStepMetadata(
								'transactionMonitoring'
							),
						},
						{
							key: 'transactionMonitoringFiat',
							title: 'Transaction Monitoring - Fiat',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'transactionMonitoringFiat'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'transactionMonitoringFiat'
							),
							metadata: this.getStepMetadata(
								'transactionMonitoringFiat'
							),
						},
						{
							key: 'transactionMonitoringCrypto',
							title: 'Transaction Monitoring - Crypto',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'transactionMonitoringCrypto'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'transactionMonitoringCrypto'
							),
							metadata: this.getStepMetadata(
								'transactionMonitoringCrypto'
							),
						},
						{
							key: 'cryptoExchanges',
							title: 'Blockchain/Crypto Asset companies',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'cryptoExchanges'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'cryptoExchanges'
							),
							metadata: this.getStepMetadata('cryptoExchanges'),
						},
						{
							key: 'blockchainCrypto',
							title: 'Blockchain/Crypto',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'blockchainCrypto'
								],
							show:
								true ??
								this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
									'blockchainCrypto'
								),
							metadata: this.getStepMetadata('blockchainCrypto'),
						},
						{
							key: 'lastly',
							title: 'Lastly',
							complete:
								this.onboarding?.due_diligence_form?.steps[
									'lastly'
								],
							show: this.onboarding?.due_diligence_form?.steps?.hasOwnProperty(
								'lastly'
							),
							metadata: this.getStepMetadata('lastly'),
						},
					],
				},
				{
					key: 'scam_claim',
					title: 'Scam Claim',
					complete: !!this.onboarding?.steps['scam_claim'],
					show: this.onboarding?.steps?.hasOwnProperty('scam_claim'),
					metadata: this.getStepMetadata('scam_claim'),
				},
				{
					key: 'tax_forms',
					title: 'Upload Tax Forms',
					complete: !!this.onboarding?.steps['tax_forms'],
					optional: true,
					show: this.onboarding?.steps?.hasOwnProperty('tax_forms') && this.onboarding.tax_forms,
					metadata: this.getStepMetadata('tax_forms'),
				},
				{
					key: 'documents',
					title: 'Upload Documents',
					complete: !!this.onboarding?.steps['documents'],
					show: this.onboarding?.required_documents?.length,
					metadata: this.getStepMetadata('documents'),
				},
				{
					key: 'terms',
					title: 'Review & Submit',
					complete: !!this.onboarding?.steps['terms'],
					show: true,
					metadata: this.getStepMetadata('terms'),
				},
			],
		};
	}

	private getStepMetadata(stepKey: string): StepModelMetadata {
		const review = this.onboarding.latestReview?.review?.reviews.find(
			(review: OnboardingReviewItem) => review.section_id === stepKey
		);
		const metadata: StepModelMetadata = {
			status: undefined,
			disable: !!this.onboarding.latestReview?.review?.reviews,
			comment: review?.comment,
		};

		if (review) {
			switch (review?.status) {
				case REVIEW_STATUS.APPROVED:
					metadata.disable = true;
					break;
				case REVIEW_STATUS.REJECTED:
					metadata.status = 'ERROR';
					metadata.disable = false;
					break;
				default:
					metadata.disable = false;
					break;
			}

			if (
				this.onboarding.steps[stepKey] ||
				this.onboarding.due_diligence_form?.steps[stepKey]
			) {
				metadata.status = 'UPDATED';
			}
		}

		return metadata;
	}
}
