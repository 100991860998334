import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OtpComponent} from './_general/otp/otp.component';
import {StepperModule} from '../stepper/stepper.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE,} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgOtpInputModule} from 'ng-otp-input';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CompleteComponent} from './_general/complete/complete.component';
import {SourceOfFundsComponent} from './individual/source-of-funds/source-of-funds.component';
import {VerificationDocumentsComponent} from './individual/verification-documents/verification-documents.component';
import {OnboardingHomeComponent} from './_general/onboarding-home/onboarding-home.component';
import {ErrorComponent} from './_general/error/error.component';
import {CompleteLaterComponent} from './_general/complete-later/complete-later.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AuthComponent} from './_general/auth/auth.component';
import {ConfirmationModalComponent} from './_general/confirmation-modal/confirmation-modal.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ReviewLegalComponent} from './individual/review-legal/review-legal.component';
import {
	IndividualEntityRegisteredAddressComponent
} from './individual/entity-registered-address/entity-registered-address.component';
import {IndividualPersonalDetailsComponent} from './individual/personal-details/personal-details.component';
import {IndividualWelcomeComponent} from './individual/welcome/welcome.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter,} from '@angular/material-moment-adapter';
import {DateUtil} from '../../core/utils/date.util';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import {CorporatePagesModule} from './corporate/corporate-pages.module';
import {PageWrapperComponent} from './page-wrapper/page-wrapper.component';
import {EntityUtils} from '../../core/utils/entity.util';
import {Ng2TelInputModule} from "../../core/directives/ng2-tel-input/ng2-tel-input.module";

@NgModule({
	declarations: [
		// General pages
		AuthComponent,
		OnboardingHomeComponent,
		OtpComponent,
		ErrorComponent,
		CompleteComponent,
		CompleteLaterComponent,
		ConfirmationModalComponent,
		PageWrapperComponent,

		// Individual pages
		IndividualWelcomeComponent,
		IndividualPersonalDetailsComponent,
		IndividualEntityRegisteredAddressComponent,
		VerificationDocumentsComponent,
		SourceOfFundsComponent,
		ReviewLegalComponent,
	],
	exports: [PageWrapperComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		StepperModule,
		CorporatePagesModule,
		NgOtpInputModule,
		RouterModule,

		// Angular MaterialMatNativeDateModule,

		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		MatRadioModule,
		MatDatepickerModule,
		MatSelectModule,
		MatSnackBarModule,
		Ng2TelInputModule,
		MatTableModule,
		MatDialogModule,
		MatCheckboxModule,
		MatBadgeModule,
		MatTabsModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSliderModule,
		MatCardModule,
	],
	providers: [
		MatDatepickerModule,
		EntityUtils,
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: DateUtil.DATE_PICKER_FORMAT },
	],
	entryComponents: [ConfirmationModalComponent],
})
export class PagesModule {}
