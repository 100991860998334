import { Component, Input, OnInit, Self } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NgControl,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ERROR_MESSAGES,
	MOBILE_NUMBER_ERRORS,
} from '../../constants/enum.const';
import { Country } from '../../models/country.interface';
import { StaticData } from '../../constants/static.const';
import { ArrayUtils } from '../../utils/array.util';

@Component({
	selector: 'bcb-generic-mobile',
	templateUrl: './generic-mobile.component.html',
	styleUrls: ['./generic-mobile.component.scss'],
})
export class GenericMobileComponent implements ControlValueAccessor, OnInit {
	@Input() question!: ConfigQuestion;
	@Input() errorMessage?: ERROR_MESSAGES | string;
	mobileCountryCode?: Country = StaticData.countries.find(
		(country: Country) => country.code === 'GB'
	);
	control!: FormControl;
	disabled!: boolean;
	telInputOptions: Record<string, any> = {
		initialCountry: 'gb',
		customContainer: 'iti__no-flag',
		preferredCountries: ['gb', 'ch', 'us'],
		separateDialCode: true,
	};
	objMobileInput: any;
	private allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);
	@Input() mobileCountries: Array<Country> = this.allCountries.filter(
		(country) => country.call_code
	);

	constructor(@Self() private readonly controlDirective: NgControl) {
		controlDirective.valueAccessor = this;
	}

	onChange: (value: any) => void = () => {};

	onTouched: () => void = () => {};

	ngOnInit(): void {
		this.control = this.controlDirective.control as FormControl;
	}

	writeValue(value: any): void {
		if (!this.objMobileInput) {
			return;
		}
	}

	registerOnChange(onChange: (value: any) => void): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void): void {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	onMobileChange(): void {
		this.onChange(this.objMobileInput.getNumber());
	}
}
