import { NgModule } from '@angular/core';
import { ObjectArrayKeyValuePipe } from './object-array-key-value/object-array-key-value.pipe';

@NgModule({
	declarations: [ObjectArrayKeyValuePipe],
	imports: [],
	providers: [ObjectArrayKeyValuePipe],
	exports: [ObjectArrayKeyValuePipe],
})
export class PipesModule {}
