import {Component, OnInit} from '@angular/core';
import {EntityQueries} from '../../core/store/entity/entity.queries';
import {EntityStateModel} from '../../core/store/entity/entity.state';
import {ShareholdersQueries} from "../../core/store/shareholders/shareholders.queries";
import {HttpService} from "../../core/services/http/http.service";
import {DirectorsQueries} from "../../core/store/directors/directors.queries";
import {Router} from "@angular/router";
import {APP_ROUTES} from "../../core/constants/enum.const";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
    selector: 'bcb-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    verified: boolean = false;
    systemUser: BehaviorSubject<string | undefined>
    entityName?: string | null;

    constructor(private readonly entityQueries: EntityQueries,
                private readonly shareholdersQueries: ShareholdersQueries,
                private readonly directorsQueries: DirectorsQueries,
                private readonly http: HttpService,
                private readonly router: Router) {
        this.systemUser = this.http.systemUser;
    }

    ngOnInit(): void {
        this.entityQueries.getState().subscribe((res: EntityStateModel) => {
            this.verified = !!res?.id;
            this.entityName = res?.entity_name ?? `${res?.first_name} ${res?.last_name}`;
        });
    }

    completeLater(): void {
        this.http.saveAndCompleteLater().subscribe(() => {
            this.logout();
            this.clearState();
            this.router.navigate([`/${APP_ROUTES.completeLater}`], {
                skipLocationChange: true
            })
        });
    }

    logout(): void {
        this.http.logout().then(() => {
        this.clearState();
        window.location.reload()
        });
    }

    help(): void {
        window.open(`mailto:onboarding@bcbgroup.io?subject=${this.entityName} | Onboarding issue`);
    }

    private clearState(): void {
        this.directorsQueries.clearState();
        this.shareholdersQueries.clearState();
        this.entityQueries.clearState();
    }
}
